import React from 'react'
import { useFormContext } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { Banner } from '@sevenrooms/core/ui-kit/layout'
import { Box } from '@sevenrooms/react-components/components/Box'
import { posiMetaDataMap } from '../../../ReferenceData'
import { BaseAutomaticPrepaymentRedemptionCard } from '../BaseAutomaticPrepaymentRedemptionCard'
import { messages } from './SquareAutomaticPrepaymentRedemptionCard.locales'
import type { AutomaticPrepaymentRedemptionCardFormField } from '../AutomaticPrepaymentRedemptionCardForm.zod'

export interface SquareAutomaticPrepaymentRedemptionCardProps {
  field: AutomaticPrepaymentRedemptionCardFormField
  isLocation: boolean
}

export function SquareAutomaticPrepaymentRedemptionCard({ field, isLocation }: SquareAutomaticPrepaymentRedemptionCardProps) {
  const { formatMessage } = useLocales()
  const { watch } = useFormContext()
  const selectedLocationId = watch('locationSelectionCard.locationId')

  return (
    <BaseAutomaticPrepaymentRedemptionCard
      docLink={posiMetaDataMap.SQUARE.docLink}
      isAutomaticPrepaymentRedemptionEnabledField={field.prop('isAutomaticPrepaymentRedemptionEnabled')}
      disabled={!selectedLocationId}
    >
      {!isLocation && (
        <Box width="100%">
          <Banner
            type="info"
            title={formatMessage(messages.locationRequiredBannerTitle)}
            description={formatMessage(messages.locationRequiredBannerDescription)}
          />
        </Box>
      )}
    </BaseAutomaticPrepaymentRedemptionCard>
  )
}
