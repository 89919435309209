import { defineMessages } from '@sevenrooms/core/locales'

export const messages = defineMessages({
  locationRequiredBannerTitle: {
    id: 'posiSettings.SquareAutomaticPrepaymentRedemptionCard.locationRequiredBannerTitle',
    defaultMessage: 'Location Required',
  },
  locationRequiredBannerDescription: {
    id: 'posiSettings.SquareAutomaticPrepaymentRedemptionCard.locationRequiredBannerDescription',
    defaultMessage: 'To enable seamless prepayment sync, please select a location and save your selection.',
  },
} as const)
