import { useMemo } from 'react'
import type { POSIConfig, POSILocation } from '@sevenrooms/core/domain'
import { type Field, z, type ZodSchema } from '@sevenrooms/core/form'
import {
  convertAutomaticCheckCreationCardFormDataToPOSIConfig,
  getAutomaticCheckCreationCardFormDefaultValues,
  useAutomaticCheckCreationCardForm,
} from '../../../AutomaticCheckCreationCard/AutomaticCheckCreationCardForm.zod'
import {
  convertAutomaticPrepaymentRedemptionCardFormDataToPOSIConfig,
  getAutomaticPrepaymentRedemptionCardFormDefaultValues,
  useAutomaticPrepaymentRedemptionCardForm,
} from '../../../AutomaticPrepaymentRedemptionCard/AutomaticPrepaymentRedemptionCardForm.zod'
import {
  getCourseStatusMappingCardFormDefaultValues,
  convertCourseStatusMappingCardFormDataToPOSIConfig,
  useCourseStatusMappingCardForm,
} from '../../../CourseStatusMappingCard/CourseStatusMappingCardForm.zod'
import {
  getLocationSelectionCardFormDefaultValues,
  convertLocationSelectionCardFormDataToPOSIConfig,
  useLocationSelectionCardForm,
} from '../../../LocationSelectionCard/LocationSelectionCardForm.zod'

export type SquareEditPageFormData = ZodSchema<typeof useSquareEditPageForm>
export type SquareEditPageFormField = Field<SquareEditPageFormData>

export function useSquareEditPageForm() {
  const locationSelectionCard = useLocationSelectionCardForm()
  const automaticCheckCreationCard = useAutomaticCheckCreationCardForm()
  const automaticPrepaymentRedemptionCard = useAutomaticPrepaymentRedemptionCardForm()
  const courseStatusMappingCard = useCourseStatusMappingCardForm()

  return useMemo(
    () =>
      z.object({
        locationSelectionCard,
        automaticCheckCreationCard,
        automaticPrepaymentRedemptionCard,
        courseStatusMappingCard,
      }),
    [automaticCheckCreationCard, automaticPrepaymentRedemptionCard, courseStatusMappingCard, locationSelectionCard]
  )
}

export const getSquareEditPageFormDefaultValues = (config: POSIConfig): SquareEditPageFormData => ({
  locationSelectionCard: getLocationSelectionCardFormDefaultValues(config.location?.id),
  automaticCheckCreationCard: getAutomaticCheckCreationCardFormDefaultValues({
    isAutomaticCheckCreationEnabled: config.checkCreationEnabled,
  }),
  automaticPrepaymentRedemptionCard: getAutomaticPrepaymentRedemptionCardFormDefaultValues({
    isAutomaticPrepaymentRedemptionEnabled: config.depositsEnabled,
  }),
  courseStatusMappingCard: getCourseStatusMappingCardFormDefaultValues(config),
})

export const convertSquareEditPageFormDataToPOSIConfig = ({
  config,
  locations,
  data,
}: {
  config: POSIConfig
  locations: POSILocation[]
  data: SquareEditPageFormData
}): POSIConfig => ({
  lastSaved: config.lastSaved,
  ...convertAutomaticCheckCreationCardFormDataToPOSIConfig({
    isAutomaticCheckCreationEnabled: data.automaticCheckCreationCard.isAutomaticCheckCreationEnabled,
  }),
  ...convertLocationSelectionCardFormDataToPOSIConfig({ locations, data: data.locationSelectionCard }),
  ...convertAutomaticPrepaymentRedemptionCardFormDataToPOSIConfig({
    isAutomaticPrepaymentRedemptionEnabled: data.automaticPrepaymentRedemptionCard.isAutomaticPrepaymentRedemptionEnabled,
  }),
  ...convertCourseStatusMappingCardFormDataToPOSIConfig(data.courseStatusMappingCard),
})
